var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-result", {
        attrs: {
          status: "403",
          title: "403",
          "sub-title": "Sorry, you are not authorized to access this page."
        },
        scopedSlots: _vm._u([
          {
            key: "extra",
            fn: function() {
              return [
                _c(
                  "router-link",
                  { attrs: { to: "/" } },
                  [
                    _c("a-button", { attrs: { type: "primary" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lbl_back")) + " to Dashboard "
                      )
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }